import React from "react";
import { Link } from "gatsby";

const CustomPrismicLink = ({ target, to = "", children, ...props }) => {
  if (!to) return null;
  if (target === "_blank" || to?.indexOf("://") > -1) {
    return (
      <a href={to} target={target} {...props} rel={"noreferrer"}>
        {children}
      </a>
    );
  }
  return (
    <Link to={to} {...props}>
      {children}
    </Link>
  );
};

export default CustomPrismicLink;
